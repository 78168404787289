<template>
  <select-available-segments-dropdown :value="activeSegment"
                                      :hide-selector="hideSelector"
                                      :variant="variant"
                                      :size="size"
                                      @clearFilters="clearFilters"
                                      @input="activeSegment = $event; loadExistingSegment($event)" />
</template>

<script>
import SelectAvailableSegmentsDropdown from '@/components/test/selectAvailableSegmentsDropdown'
export default {
  name: 'selectAvailableSegments',
  components: { SelectAvailableSegmentsDropdown },
  props: {
    hideSelector: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    reloadAccounts: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String
    }
  },
  data () {
    return {
      segments: [],
      deleteMode: false,
      deleteInProgress: false
    }
  },
  computed: {
    activeSegment: {
      get () {
        return this.$store.getters.activeSegment
      },
      set (segment) {
        this.$store.commit('setActiveSegment', segment)
      }
    }
  },
  methods: {
    async loadExistingSegment (segment) {
      this.activeSegment = segment
      await this.$store.dispatch('reloadAccounts')
      this.$emit('change')
    },
    async clearFilters () {
      await this.$store.dispatch('clearAllFilters')
      this.$emit('change')
    }
  }
}
</script>

<style scoped>

</style>
